<script setup lang="ts">
import { useBookingStore } from '~/stores/booking'

defineProps<{
  ticketCount: number
  embedded?: boolean
}>()

const bookingStore = useBookingStore()
</script>

<template>
  <div class="grid grid-cols-[1fr_auto] gap-x-5 gap-y-2 text-lg">
    <template v-if="!embedded">
      <div class="flex items-center gap-5">
        <p class="flex-none font-light">Kosten pro Person</p>
        <div class="h-0.5 w-full bg-stone-100" />
      </div>
      <p class="flex-none text-right text-2xl font-bold">
        {{ formatCurrency(bookingStore.offer?.price ?? 0) }}
      </p>
    </template>
    <div class="flex items-center gap-5">
      <p class="flex-none font-light text-al-primary">Gesamtbetrag</p>
      <div class="h-0.5 w-full bg-al-primary" />
    </div>
    <p class="flex-none text-right text-2xl font-bold sm:text-3xl">
      {{ formatCurrency((bookingStore.offer?.price ?? 0) * ticketCount) }}
    </p>
  </div>
</template>
