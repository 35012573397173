import { useQuery } from '@tanstack/vue-query'
import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

import type { PERSONAL_FILE_SCHEMA } from '~/types/booking'

import type { z } from 'zod'

export const useBookingStore = defineStore('booking', () => {
  // state
  const offerSlug = ref<string>()

  const page = ref(1)
  const itemsPerPage = ref(5)

  const {
    data: offer,
    error,
    isLoading,
  } = useQuery({
    queryKey: [
      'offer',
      computed(() => offerSlug.value ?? '-1'),
      computed(() => ({ page: page.value, itemsPerPage })),
    ] as const,
    queryFn: async ({ queryKey: [_, offerSlugValue, paginationValue] }) =>
      fetchOfferWithAppointmentsBySlug(offerSlugValue, paginationValue)
        .throwOnError()
        .then((response) => response.data),
    enabled: () => !!offerSlug.value,
  })

  const ticketCount = ref(1)
  const selectedAppointmentId = ref<string>()

  const personalInfo = reactive<z.infer<typeof PERSONAL_FILE_SCHEMA>>(
    import.meta.dev
      ? {
          email: 'test@falcondev.de',
          phoneNumber: '0123 456789',
          address: {
            firstName: 'Falco',
            lastName: 'Dev',
            streetAndNumber: 'Falconer Str. 69',
            postalCode: '42069',
            city: 'Falkenberg',
          },
        }
      : {
          email: '',
          phoneNumber: '',
          address: { firstName: '', lastName: '', streetAndNumber: '', postalCode: '', city: '' },
        },
  )

  // getters and setters
  const selectedAppointment = computed(() => {
    return offer.value?.offer_appointment?.find(
      (appointment) => appointment.id === selectedAppointmentId.value,
    )
  })

  const init = (slug: string, embedded: boolean) => {
    offerSlug.value = slug
    const oldPage = page.value
    const oldItemsPerPage = itemsPerPage.value

    itemsPerPage.value = embedded ? 4 : 5
    const selectedIndex = selectedAppointment.value
      ? offer.value?.offer_appointment.indexOf(selectedAppointment.value)
      : -1

    if (selectedIndex === -1 || selectedIndex === undefined) {
      page.value = 1
      return
    }

    page.value =
      Math.floor((selectedIndex + (oldPage - 1) * oldItemsPerPage) / itemsPerPage.value) + 1
  }

  return {
    offer,
    error,
    isLoading,
    personalInfo,
    init,
    selectedAppointment,
    selectedAppointmentId,
    ticketCount,
    page,
    itemsPerPage,
  }
})
