<script setup lang="ts">
import { useField } from 'vee-validate'

const props = defineProps<{
  name: string
  min?: number
  max?: number
}>()

const { value } = useField<number>(() => props.name)
</script>

<template>
  <div class="flex items-stretch gap-2 border-2 py-4">
    <button type="button" class="px-3 text-al-secondary" @click="() => value > 1 && value--">
      -
    </button>
    <input
      type="number"
      class="number-input text-center text-lg outline-none"
      :value="value"
      :min="min"
      :max="max"
      @input="(event) => (value = (event.target as HTMLInputElement).valueAsNumber)"
      @keydown.enter.prevent
    />
    <button type="button" class="px-3 text-al-secondary" @click="() => value < 99 && value++">
      +
    </button>
  </div>
</template>

<style scoped lang="css">
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
