import { z } from 'zod'

export const ADDRESS_SCHEMA = z.object({
  city: z.string().min(1, 'Stadt erforderlich'),
  firstName: z.string().min(1, 'Vorname erforderlich'),
  lastName: z.string().min(1, 'Nachname erforderlich'),
  streetAndNumber: z.string().min(1, 'Straße und Hausnummer erforderlich'),
  postalCode: z.string().min(1, 'Postleitzahl erforderlich'),
})

export const atelierInquirySchema = z.object({
  dateRange: z
    .array(z.date())
    .length(2, 'Zeitraum erforderlich')
    .refine((v) => v[0] > new Date(), 'Start-Datum muss in der Zukunft liegen'),
  name: z.string().min(1, 'Name erforderlich'),
  email: z.string().email('Ungültige E-Mail-Adresse'),
  message: z.string().max(2000, 'Nachricht zu lang'),
  privacy: z.boolean().refine((v) => v, 'Einverständnis erforderlich'),
})
