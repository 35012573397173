<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { DateTime } from 'luxon'
import { useForm } from 'vee-validate'
import { z } from 'zod'

import { useBookingStore } from '~/stores/booking'

const props = withDefaults(
  defineProps<{
    embedded?: boolean
    offerSlugId?: string
  }>(),
  {
    embedded: false,
  },
)

const bookingStore = useBookingStore()
const editMode = useEditMode()

if (props.offerSlugId) {
  bookingStore.init(props.offerSlugId, props.embedded)
}

const initialValues = computed(() => ({
  ticketCount: bookingStore.ticketCount,
  selectedAppointmentId: bookingStore.selectedAppointmentId,
}))

const { values, defineComponentBinds, handleSubmit, errors } = useForm({
  validationSchema: computed(() => {
    const allowedAppointmentIds = bookingStore.offer?.offer_appointment.map((a) => a.id) ?? ['']

    return toTypedSchema(
      z.object({
        selectedAppointmentId: z
          .string({
            required_error: 'Es muss ein Termin ausgewählt werden',
          })
          .refine((id) => {
            return allowedAppointmentIds.includes(id)
          }, 'Es muss ein Termin ausgewählt werden'),
        ticketCount: z.coerce
          .number({
            invalid_type_error: 'Es muss eine Zahl eingegeben werden',
          })
          .min(1, 'Es muss mindestens ein Ticket gebucht werden')
          .max(
            bookingStore.selectedAppointment?.remaining_slots ?? 1,
            'Es sind nicht genügend Plätze verfügbar',
          ),
      }),
    )
  }),
  initialValues: initialValues.value,
})

watch(
  () => values.selectedAppointmentId,
  (id) => {
    if (!id) return
    bookingStore.selectedAppointmentId = id
  },
)

async function submit(callback: () => void) {
  void handleSubmit(async (v) => {
    bookingStore.ticketCount = v.ticketCount
    bookingStore.selectedAppointmentId = v.selectedAppointmentId
    callback()
  })()
}

defineExpose({
  submit,
})

const selectedAppointmentId = defineComponentBinds('selectedAppointmentId')
</script>

<template>
  <div v-if="editMode">
    <p class="text-center">
      Hinweis: An dieser Stelle können die Besucher dieser Seite Termine buchen.
    </p>
  </div>
  <div v-else class="contents">
    <AlStepSection
      caption="Termin auswählen"
      name="selectedAppointmentId"
      :error="errors.selectedAppointmentId"
      :embedded="embedded"
    >
      <div
        v-if="bookingStore.offer && bookingStore.offer.offer_appointment.length > 0"
        class="flex w-full flex-col items-center gap-8"
      >
        <HeadlessRadioGroup
          v-bind="selectedAppointmentId"
          class="grid grid-cols-2 justify-center gap-4 sm:flex sm:flex-wrap md:gap-8"
        >
          <div v-if="bookingStore.isLoading" class="contents">
            <USkeleton
              v-for="skeleton in bookingStore.itemsPerPage"
              :key="skeleton"
              class="h-32 w-32"
            />
          </div>
          <HeadlessRadioGroupOption
            v-for="(appointment, index) in bookingStore.offer.offer_appointment"
            v-else
            :key="index"
            v-slot="{ checked }"
            :value="appointment.id"
            class="flex h-32 w-32 cursor-pointer flex-col items-center justify-center bg-stone-100 aria-checked:bg-al-primary"
          >
            <p class="text-xl font-semibold">
              Termin {{ bookingStore.itemsPerPage * (bookingStore.page - 1) + index + 1 }}
            </p>
            <p class="mb-2 text-sm">
              {{ appointment.remaining_slots }}
              {{ appointment.remaining_slots !== 1 ? 'Plätze' : 'Platz' }}
            </p>
            <div class="contents" :class="checked ? 'text-white' : 'text-al-primary-500'">
              <p>{{ DateTime.fromISO(appointment.timestamp).toFormat('dd.MM.yyyy') }}</p>
              <p>{{ DateTime.fromISO(appointment.timestamp).toFormat('HH:mm') }}</p>
            </div>
          </HeadlessRadioGroupOption>
        </HeadlessRadioGroup>
        <UPagination
          v-model="bookingStore.page"
          class="self-center"
          :page-count="bookingStore.itemsPerPage"
          :total="bookingStore.offer.offer_appointment.length"
        />
      </div>
      <div v-else class="flex w-full flex-col items-center">
        <p>Es sind keine Termine verfügbar</p>
      </div>
    </AlStepSection>
    <div class="flex-1" />
    <AlStepSection
      caption="Personen hinzufügen"
      name="ticketCount"
      :error="errors.ticketCount"
      class="flex justify-between gap-8"
      :embedded="embedded"
    >
      <div class="flex w-full flex-col justify-between gap-8 sm:flex-row sm:items-center sm:gap-16">
        <div class="flex items-center gap-6">
          <AlCounterInput name="ticketCount" :min="1" :max="99" />
          <span>Personen</span>
        </div>
        <AlSlideoutButton
          v-if="embedded"
          class="w-full"
          expanded
          variant="secondary"
          :href="`/buchung?slug=${props.offerSlugId}&ticket-count=${values.ticketCount}${
            values.selectedAppointmentId ? `&appointment-id=${values.selectedAppointmentId}` : ''
          }`"
        >
          Buchen
        </AlSlideoutButton>
      </div>
    </AlStepSection>
    <AlStepSection caption="Zwischensumme" name="priceSum" :embedded="embedded">
      <AlPriceSection :ticket-count="values?.ticketCount ?? 0" :embedded="embedded" />
    </AlStepSection>
  </div>
</template>
